import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Link } from '@reach/router';
import styles from './Button.module.scss';

Button.propTypes = {
    customClassName: PropTypes.string,
    theme: PropTypes.string,
    size: PropTypes.string,
    type: PropTypes.string,
    children: PropTypes.node,
};

export const BUTTON_THEMES = {
    GREEN: 'green',
    ORANGE: 'orange',
    BLUE: 'blue',
    BLUE_REACT: 'blueReact',
    YELLOW: 'yellow',
    // WHITE: 'white',
    BLACK: 'black',
    LINK_WHITE: 'linkWhite',
    LINK_TRANSPARENT: 'linkTransparent',
};

export const BUTTON_SIZES = {
    XS: 'xs',
    SM: 'sm',
    MD: 'md',
    LG: 'lg',
    XL: 'xl',
    XXL: 'xxl',
};

const BUTTON_TYPES = {
    BUTTON: 'button',
    REGULAR_LINK_AS_BUTTON: 'regularLinkAsButton',
    ROUTER_LINK_AS_BUTTON: 'routerLinkAsButton',
    ROUTER_LINK_AS_LINK: 'routerLinkAsLink',
};

function Button({
    className,
    theme = BUTTON_THEMES.BLUE,
    size = BUTTON_SIZES.MD,
    type = BUTTON_TYPES.BUTTON,
    href,
    children,
    ...rest
}) {
    return (
        <React.Fragment>
            {type === BUTTON_TYPES.BUTTON && (
                <button
                    className={cx(
                        styles.btn,
                        styles[theme],
                        styles[size],
                        className
                    )}
                    {...rest}
                >
                    {children}
                </button>
            )}
            {type === BUTTON_TYPES.REGULAR_LINK_AS_BUTTON && (
                <a
                    className={cx(
                        styles.regularLinkAsButton,
                        styles[theme],
                        styles[size],
                        className
                    )}
                    href={href}
                    {...rest}
                >
                    {children}
                </a>
            )}
            {type === BUTTON_TYPES.ROUTER_LINK_AS_BUTTON && (
                <Link
                    className={cx(
                        styles.routerLinkAsButton,
                        styles[theme],
                        styles[size],
                        className
                    )}
                    {...rest}
                >
                    {children}
                </Link>
            )}
            {type === BUTTON_TYPES.ROUTER_LINK_AS_LINK && (
                <Link
                    className={cx(
                        styles.routerLinkAsLink,
                        styles[theme],
                        styles[size],
                        className
                    )}
                    {...rest}
                >
                    {children}
                </Link>
            )}
        </React.Fragment>
    );
}

export default {
    Component: Button,
    Theme: BUTTON_THEMES,
    Size: BUTTON_SIZES,
    Type: BUTTON_TYPES,
};
