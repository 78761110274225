import React from 'react';
import cx from 'classnames';
import styles from './Container.module.scss';

export default ({
    children,
    fullHeight,
    noTopBottomPadding,
    size,
    className,
}) => (
    <div
        className={cx(
            styles.container,
            fullHeight && styles.fullHeight,
            noTopBottomPadding && styles.noTopBottomPadding,
            size && styles[`size${size}`],
            className
        )}
    >
        {children}
    </div>
);
