import React, { useState, useEffect, useRef } from 'react';
import cx from 'classnames';
import Container from '../Container/Container';
import SvgComponent from '../../assets/svg/SvgComponent';
import styles from './NavBar.module.scss';
import Button from '../Button/Button';

const NavBar = () => {
    const mobileMenuContentNode = useRef();
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    useEffect(() => {
        if ('matchMedia' in window) {
            if (window.matchMedia('(max-width:1200px)').matches) {
                mobileMenuContentNode.current.style.maxHeight = isMobileMenuOpen
                    ? `${mobileMenuContentNode.current.scrollHeight}px`
                    : '0px';
            }
        }
    }, [isMobileMenuOpen]);

    const handleBurgerMenuClick = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    const closeBurgerMenu = () => {
        setIsMobileMenuOpen(false);
    };

    return (
        <div className={styles.navbar}>
            <Container noTopBottomPadding={true}>
                <div className={styles.container}>
                    <div className={styles.logoContainer}>
                        <Button.Component
                            type={Button.Type.ROUTER_LINK_AS_LINK}
                            theme={Button.Theme.LINK_TRANSPARENT}
                            to="/"
                            onClick={closeBurgerMenu}
                        >
                            <SvgComponent
                                className={styles.logo}
                                name="leDevWebModerne"
                            />
                        </Button.Component>
                        <Button.Component
                            type={Button.Type.BUTTON}
                            theme={Button.Theme.LINK_TRANSPARENT}
                            className={styles.burgerMenu}
                            onClick={handleBurgerMenuClick}
                        >
                            <SvgComponent name="menu" />
                        </Button.Component>
                    </div>

                    <nav ref={mobileMenuContentNode} className={cx(styles.nav)}>
                        <div className={styles.menuItemContainer}>
                            <Button.Component
                                type={Button.Type.ROUTER_LINK_AS_LINK}
                                theme={Button.Theme.LINK_WHITE}
                                size={Button.Size.SM}
                                to="/"
                                className={styles.link}
                                onClick={closeBurgerMenu}
                            >
                                Concept
                            </Button.Component>
                        </div>
                        <div className={styles.menuItemContainer}>
                            <Button.Component
                                type={Button.Type.ROUTER_LINK_AS_LINK}
                                theme={Button.Theme.LINK_WHITE}
                                size={Button.Size.SM}
                                to="/git"
                                className={styles.link}
                                onClick={closeBurgerMenu}
                            >
                                Capsules
                            </Button.Component>
                        </div>
                        {/* <div className={styles.menuItemContainer}>
                            <Button.Component
                                type={Button.Type.ROUTER_LINK_AS_LINK}
                                theme={Button.Theme.LINK_WHITE}
                                size={Button.Size.SM}
                                to="/javascript"
                                className={styles.link}
                                onClick={closeBurgerMenu}
                            >
                                Les capsules Javascript
                            </Button.Component>
                        </div>
                        <div className={styles.menuItemContainer}>
                            <Button.Component
                                type={Button.Type.ROUTER_LINK_AS_LINK}
                                theme={Button.Theme.LINK_WHITE}
                                size={Button.Size.SM}
                                to="/react"
                                className={styles.link}
                                onClick={closeBurgerMenu}
                            >
                                Les capsules React
                            </Button.Component>
                        </div> 
                        */}
                        <div className={styles.menuItemContainer}>
                            <Button.Component
                                type={Button.Type.ROUTER_LINK_AS_LINK}
                                theme={Button.Theme.LINK_WHITE}
                                size={Button.Size.SM}
                                to="/blog"
                                className={styles.link}
                                onClick={closeBurgerMenu}
                            >
                                Blog
                            </Button.Component>
                        </div>

                        <div className={styles.menuItemContainer}>
                            <Button.Component
                                theme={Button.Theme.BLUE}
                                type={Button.Type.REGULAR_LINK_AS_BUTTON}
                                size={Button.Size.SM}
                                href={process.env.GATSBY_APP_LOGIN_URL}
                                onClick={closeBurgerMenu}
                                className={styles.btnConnect}
                            >
                                Espace client
                            </Button.Component>
                        </div>
                    </nav>
                </div>
            </Container>
        </div>
    );
};

export default NavBar;
