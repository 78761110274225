import React from 'react';
import Button from '../Button/Button';
import styles from './Footer.module.scss';

const Footer = () => {
    return (
        <footer className={styles.container}>
            <p className={styles.text}>
                Fait avec{' '}
                <span role="img" aria-label="coeur">
                    ❤️
                </span>{' '}
                à Paris. ©LeDevWebModerne {new Date().getFullYear()}, tous
                droits réservés.
            </p>

            <Button.Component
                type={Button.Type.ROUTER_LINK_AS_LINK}
                theme={Button.Theme.LINK_WHITE}
                size={Button.Size.SM}
                to="/legal/mentions-legales"
                className={styles.link}
            >
                Mentions Légales
            </Button.Component>
            <Button.Component
                type={Button.Type.ROUTER_LINK_AS_LINK}
                theme={Button.Theme.LINK_WHITE}
                size={Button.Size.SM}
                to="/legal/cgv-cgu"
                className={styles.link}
            >
                CGV / CGU
            </Button.Component>
            <Button.Component
                type={Button.Type.ROUTER_LINK_AS_LINK}
                theme={Button.Theme.LINK_WHITE}
                size={Button.Size.SM}
                to="/legal/cookie-policy"
                className={styles.link}
            >
                Politique de cookies
            </Button.Component>
        </footer>
    );
};

export default Footer;
