import React from 'react';
import PropTypes from 'prop-types';

/* -------------------------- LeDevWebModerne Logo -------------------------- */
import LeDevWebModerne from './ledevwebmoderne/logoWhite.svg';

/* ----------------------------- technos icones ----------------------------- */
import AngularMonogram from './technos/angularMonogram.svg';
import BitbucketMonogram from './technos/bitbucketMonogram.svg';
import GatsbyMonogram from './technos/gatsbyMonogram.svg';
import GitMonogram from './technos/gitMonogram.svg';
import GitMonogramWhite from './technos/gitMonogramWhite.svg';
import GithubMonogram from './technos/githubMonogram.svg';
import GitlabMonogram from './technos/gitlabMonogram.svg';
import JavascriptMonogram from './technos/javascriptMonogram.svg';
import NextjsMonogram from './technos/nextjsMonogram.svg';
import NodejsMonogram from './technos/nodejsMonogram.svg';
import PythonMonogram from './technos/pythonMonogram.svg';
import ReactMonogram from './technos/reactMonogram.svg';
import SvelteMonogram from './technos/svelteMonogram.svg';
import VueMonogram from './technos/vueMonogram.svg';

/* --------------------------- global ui elements --------------------------- */
import Menu from './others/menu.svg';
import ArrowLeft from './others/arrowleft.svg';
import Plus from './others/plus.svg';
import NewsletterConfirmation from './others/newsletterConfirmation.svg';
import NewsletterEmailCheckSucceed from './others/newsletterEmailCheckSucceed.svg';
import NewsletterEmailCheckFailed from './others/newsletterEmailCheckFailed.svg';
import NewsletterUnsubscribed from './others/newsletterUnsubscribed.svg';
import Loader from './others/loader.svg';
import ThanksHead from './others/thanksHead.svg';
import WelcomeBackHead from './others/welcomeBackHead.svg';
import SecurePayment from './others/securePayment.svg';

/* --------------------------- home illustrations --------------------------- */
import Chrono from './home/chrono.svg';
import Schemas from './home/schemas.svg';
import Cible from './home/cible.svg';
import Thunder from './home/thunder.svg';
import ChooseYourPath from './home/chooseYourPath.svg';
import ValidExpertises from './home/validExpertises.svg';
import TrackProgress from './home/trackProgress.svg';
import WhenYouWant from './home/whenYouWant.svg';
import Boat from './home/boat.svg';
import AllScreens from './home/allScreens.svg';
import DevNewbee from './home/devNewbee.svg';
import DevIntermediate from './home/devIntermediate.svg';
import DevExpert from './home/devExpert.svg';
import Present from './home/present.svg';
import Check from './home/check.svg';
import NewsletterBanner from './home/newsletterPlane.svg';
import BulbLight from './home/bulblight.svg';

/* ----------------------- Selling page illustrations ----------------------- */
import AllTechnos from './SellingPageGit/allTechnos.svg';
import NewbeeThinkExpert from './SellingPageGit/newbeeThinkExpert.svg';
import LowProgress from './SellingPageGit/lowProgress.svg';
import LostTime from './SellingPageGit/lostTime.svg';
import SuperHero from './SellingPageGit/superHero.svg';
import ComicStripe from './SellingPageGit/comicStripe.svg';
import OfferGitTurboBoost from './SellingPageGit/offerGitTurboBoost.svg';
import OfferGitKitDeSurvie from './SellingPageGit/offerGitKitDeSurvie.svg';
import SectionSuperHeroOrange from './SellingPageGit/sectionSuperHeroOrange.svg';
import CapsuleArrow from './SellingPageGit/capsuleArrow.svg';

/* ------------------------------- Coming soon ------------------------------ */
import ComingSoonJavacript from './comingsoon/comingsoonJavascript.svg';
import ComingSoonReact from './comingsoon/comingsoonReact.svg';

/* --------------------------------- Concept -------------------------------- */
import LotOfSchemas from './concept/lotOfSchemas.svg';
import SplitIntoCapsules from './concept/splitIntoCapsules.svg';
import SimpleAndEffective from './concept/simpleAndEffective.svg';

const COMPONENT_TRANSCO = {
    /* -------------------------- LeDevWebModerne Logo -------------------------- */
    leDevWebModerne: LeDevWebModerne,

    /* --------------------------- global ui elements --------------------------- */
    menu: Menu,
    arrowLeft: ArrowLeft,
    plus: Plus,
    newsletterConfirmation: NewsletterConfirmation,
    newsletterEmailCheckSucceed: NewsletterEmailCheckSucceed,
    newsletterEmailCheckFailed: NewsletterEmailCheckFailed,
    newsletterUnsubscribed: NewsletterUnsubscribed,
    loader: Loader,
    thanksHead: ThanksHead,
    welcomeBackHead: WelcomeBackHead,
    securePayment: SecurePayment,

    /* ------------------------------ techno icones ----------------------------- */
    angularMonogram: AngularMonogram,
    bitbucketMonogram: BitbucketMonogram,
    gatsbyMonogram: GatsbyMonogram,
    gitMonogram: GitMonogram,
    gitMonogramWhite: GitMonogramWhite,
    githubMonogram: GithubMonogram,
    gitlabMonogram: GitlabMonogram,
    javascriptMonogram: JavascriptMonogram,
    nextjsMonogram: NextjsMonogram,
    nodejsMonogram: NodejsMonogram,
    pythonMonogram: PythonMonogram,
    reactMonogram: ReactMonogram,
    svelteMonogram: SvelteMonogram,
    vueMonogram: VueMonogram,

    /* --------------------------- home illustrations --------------------------- */
    chrono: Chrono,
    schemas: Schemas,
    cible: Cible,
    thunder: Thunder,
    chooseYourPath: ChooseYourPath,
    validExpertises: ValidExpertises,
    trackProgress: TrackProgress,
    whenYouWant: WhenYouWant,
    boat: Boat,
    allScreens: AllScreens,
    devNewbee: DevNewbee,
    devIntermediate: DevIntermediate,
    devExpert: DevExpert,
    present: Present,
    check: Check,
    newsletterPlane: NewsletterBanner,
    bulblight: BulbLight,

    /* ----------------------- selling page illustrations ----------------------- */
    allTechnos: AllTechnos,
    newbeeThinkExpert: NewbeeThinkExpert,
    lowProgress: LowProgress,
    lostTime: LostTime,
    superHero: SuperHero,
    comicStripe: ComicStripe,
    offerGitTurboBoost: OfferGitTurboBoost,
    offerGitKitDeSurvie: OfferGitKitDeSurvie,
    sectionSuperHeroOrange: SectionSuperHeroOrange,
    capsuleArrow: CapsuleArrow,

    /* ------------------------- Coming Soon javascript ------------------------- */
    comingSoonJavascript: ComingSoonJavacript,
    comingSoonReact: ComingSoonReact,

    lotOfSchemas: LotOfSchemas,
    splitIntoCapsules: SplitIntoCapsules,
    simpleAndEffective: SimpleAndEffective,
};

const SvgComponent = ({ name, ...rest }) => {
    const Component = COMPONENT_TRANSCO[!name ? 'LeDevWebModerne' : name];

    return Component ? <Component {...rest} /> : null;
};

SvgComponent.propTypes = {
    name: PropTypes.string,
};

SvgComponent.defaultProps = {
    name: 'leDevWebModerne',
};

export default SvgComponent;
