import React from 'react';
import PropTypes from 'prop-types';
// import { useStaticQuery, graphql } from "gatsby"

import NavBar from '../NavBar/NavBar';
import Footer from '../Footer/Footer';

import 'normalize.css';
import '../../styles/common.scss';
import styles from './Layout.module.scss';

const Layout = ({ className, children }) => {
    // const data = useStaticQuery(graphql`
    //   query SiteTitleQuery {
    //     site {
    //       siteMetadata {
    //         title
    //       }
    //     }
    //   }
    // `)

    return (
        <div className={className}>
            <NavBar />
            <main className={styles.main}>{children}</main>
            <Footer />
        </div>
    );
};

Layout.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
};

export default Layout;
